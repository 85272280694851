import "./styles/global.css";

//ページ遷移でスクロールをTOPに戻す
export function shouldUpdateScroll({
  routerProps: { location },
  getSavedScrollPosition,
}) {
  const currentPosition = getSavedScrollPosition(location);
  window.scrollTo(...(currentPosition || [0, 0]));
  return false;
}
